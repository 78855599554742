export const trackAnalytics = (() => {
  return (data, isPageViewVar, externalLink=false, tabSwitchVal=false) => {
    // TODO: Analytics confirmation:
    //  - 1. difference between search_terms and search_terms_typed
    //  - 2. what is the auto suggested term here? from the autocomplete?

    // Get Consumer/Business search
    const isConsumer = data.core._experienceKey.includes('privati');

    // Get applied filters if they exist
    const appliedFiltersObj = data.core.storage.storage.get('facetFilters');
    const appliedFilters = [];
    if(appliedFiltersObj) {
      const filterKeys = Object.keys(appliedFiltersObj);
      filterKeys.forEach((key) => {
        // Can have multiple filters w/the same key
        const filterWithKeyArr = appliedFiltersObj[key]
        filterWithKeyArr.forEach((currentFilter) => {
          const filterValue = currentFilter[key];
          appliedFilters.push(filterValue['$eq']);
        });
      });
    }

    // Determine if pageview using variable and queryparam
    const pvQuery = new URLSearchParams(window.location.search).has('pageview');
    const isPageView = isPageViewVar && !pvQuery;

    // Add tab switch value if applied
    if(tabSwitchVal) {
      appliedFilters.push(tabSwitchVal);
    }

    // Check if autocomplete was used
    const autocompleteContainer = document.querySelector('#yxt-SearchBar-autocomplete--SearchBar');
    let autoCompleteQuery = '';
    if(autocompleteContainer && autocompleteContainer?.dataset?.autocomplete && autocompleteContainer?.dataset?.typed) {
      autoCompleteQuery = autocompleteContainer.dataset.autocomplete;
    }

    // Get current search query
    const searchQuery = data.query ? data.query : data.core?.storage?.storage?.get('query');

    // Get number of results
    const numResults = typeof data.resultsCount == 'number' ? data.resultsCount : data.core?.storage?.storage?.get('universal-results')?.sections[0]?.results.length || 0;

    // Check if no results
    const isNoResults = numResults == 0 || data.resultsContext == 'no-results';

    // Get typed search history
    const typedHist = window.searchHistoryArr;

    // Add info to data object
    const dataObj = {
      'event_name': isNoResults ? ['search_zero_results'] : ['search_apply'],
      'event_category': 'search',
      'search_category': data.verticalKey ? data.verticalKey : 'all',
      'search_subcategory': appliedFilters,
      'search_results': isNoResults ? 0 : numResults,
      'search_terms': searchQuery,
      'search_terms_typed': typedHist ? typedHist : null,
      'search_terms_auto_suggested': autoCompleteQuery ? autoCompleteQuery : '',
      'page_environment': 'web',
      'page_name': `VFIT:${isConsumer ? 'privati' : 'business'}:search results`,
      'page_channel': new URLSearchParams(window.location.search).get('channel') || (isConsumer ? 'consumer' : 'business'),
      'page_section': 'search',
      'page_type': 'search',
      'page_country': "IT",
      'page_language': "IT",
      'page_locale': "it-IT",
      'journey_name': 'search',
      'journey_type': 'task',
    };

    // Check if on Products (Prodotti) or Offers (Offerte) pages
    const productNames = [];
    const productIds = [];
    const productBrands = [];
    const hasItems = data.verticalKey ? data.verticalKey == 'products' || data.verticalKey == 'offerte' : false;
    // Add item info if it exists
    if(hasItems) {
      data.results.forEach((result) => {
        // Add result to array if name and id exist
        if(result._raw?.name && result._raw?.id) {
          productNames.push(result._raw.name);
          productIds.push(result._raw.id);

          // If brand exists add to array
          if(result._raw?.brand) {
            productBrands.push(result._raw.brand);
          }
        }
      });

      // Add to data object if info filled out
      if(productNames.length > 0) {
        dataObj['product_name'] = productNames;
      }
      if(productIds.length > 0) {
        dataObj['product_id'] = productIds;
      }
      if(productBrands.length > 0) {
        dataObj['product_brand'] = productBrands;
      }
    }

    // If link analytics call and filter is applied (no externalLink) change associated params
    if(!isPageView && !externalLink) {
      dataObj['link_name'] = 'search filter applied';
      dataObj['event_name'] = ['search_refine'];
    }

    // If link analytics call and externalLink is called change associated params
    if(!isPageView && externalLink) {
      dataObj['link_name'] = externalLink == 'call' ? 'search exit link: call' : 'search exit link: directions';
    }

    // Send data object to tracking object in view or link function
    if(window.utag){
      try {
        if(isPageView) {
          window.utag.view(dataObj);
        } else {
          window.utag.link(dataObj);
        }
      } catch (e) {
        window.utag.DB(isPageView ? 'ERROR SENDING PAGE VIEW: ' : 'ERROR SENDING CUSTOM LINK: ' + e);
      }
    }
  }
})()

export const addICMPVals = (() => {
  return(url) => {
    // Add tracking query param to card links
    if(url) {
      const keyValMap = {
        'locations': 'negozi__',
        'help_articles': 'supporto__',
        'offerte': 'offerte__',
        'products': 'prodotti__',
        'servizi_e_vantaggi': 'servizi-e-vantaggi__'
      };
      const urlFormatted = new URL(url);
      const verticalKey = ANSWERS.core.storage.storage.get('search-config')?.verticalKey;
      const paramAddendum = verticalKey && keyValMap[verticalKey] ? keyValMap[verticalKey] : 'all__';
      const paramValue = 'cbu_web_search_' + paramAddendum;
      urlFormatted.searchParams.append('icmp', paramValue);
      return urlFormatted.toString();
    }
  }
})()

export const checkStringContainsBusinessTerm = ((value) => {
  const businessKeywords = ['offerte per aziende', 'offerte aziende', 'offerte aziendali', 'offerta per aziende', 
  'offerta business', 'offerte business', 'business', 'offerte per partite iva', 'offerta per partita iva', 
  'partite iva', 'partita iva', 'esim aziendale', 'esim per partite iva', 'esim partita iva', 'esim azienda', 
  'esim aziende', 'esim per aziende', 'onenet', 'one net', 'fissa comfort', 'fissa smart', 'dati smart', 
  'dati comfort', 'mobile smart', 'mobile comfort', 'grandi aziende'];

  return new RegExp(businessKeywords.join("|")).test(value);
});

export const normalPinSvg =
  `<svg width="22px" height="23px" viewBox="0 0 22 23" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><circle id="path-1" cx="14" cy="24" r="9"></circle><filter x="-16.7%" y="-16.7%" width="133.3%" height="138.9%" filterUnits="objectBoundingBox" id="filter-3"><feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology><feOffset dx="0" dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset><feGaussianBlur stdDeviation="0.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur><feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite><feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.237601902 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix><feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter2"></feMorphology><feOffset dx="0" dy="0" in="shadowSpreadOuter2" result="shadowOffsetOuter2"></feOffset><feGaussianBlur stdDeviation="0.5" in="shadowOffsetOuter2" result="shadowBlurOuter2"></feGaussianBlur><feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2"></feComposite><feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.12 0" type="matrix" in="shadowBlurOuter2" result="shadowMatrixOuter2"></feColorMatrix><feMerge><feMergeNode in="shadowMatrixOuter1"></feMergeNode><feMergeNode in="shadowMatrixOuter2"></feMergeNode></feMerge></filter></defs><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g transform="translate(-3.000000, -13.000000)"><mask id="mask-2" fill="white"><use xlink:href="#path-1"></use></mask><g id="Knob"><use fill="black" fill-opacity="1" filter="url(#filter-3)" xlink:href="#path-1"></use><circle stroke="#FFFFFF" stroke-width="1" fill="#E60000" fill-rule="evenodd" cx="14" cy="24" r="9.5"></circle></g></g></g></svg>`;

export const selectedPinSvg =
  `<svg width="28px" height="38px" viewBox="0 0 28 38" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g transform="translate(0.000000, -5.000000)"><g transform="translate(0.000000, 5.000000)"><path d="M14,38 C14,38 28,22.9945084 28,14.25 C28,5.50549164 21.7319866,0 14,0 C6.26801338,0 0,5.50549164 0,14.25 C0,22.9945084 14,38 14,38 L14,38 L14,38 Z" fill="#E60000"></path><g transform="translate(4.000000, 4.000000)"><circle fill="#FFFFFF" fill-rule="nonzero" cx="10" cy="10" r="10"></circle><path d="M9.69147005,15.8333333 C6.99788264,15.8333333 4.18632789,13.4423074 4.16666667,9.60042479 C4.16666667,7.04598154 5.48396854,4.61415157 7.17483364,3.1632278 C8.82637629,1.75317513 11.0874168,0.833575557 13.1125227,0.833575557 C13.3507953,0.830432515 13.5885294,0.857887717 13.8203267,0.915317741 C11.9703637,1.34194368 10.6443173,3.03307306 10.6155475,5.00242694 C10.6123564,5.05771529 10.619022,5.11314058 10.6352087,5.16591131 C13.6237145,5.92202651 14.9803388,7.80209675 15,10.3974111 C14.9708232,13.4188381 12.5985237,15.8480372 9.69147005,15.8333333 L9.69147005,15.8333333 Z" fill="#E60000"></path></g></g></g></g></svg>`;
